<template>
    <div class="crud" id="list-items">
		<div class="box" :class="{'bg-white': ['view', 'edit'].includes(viewCrud) }">
			<div class="header">
				<div class="icon-header"><icon-pipeline /></div>
				<div class="name">{{ pipelineSelected ? pipelineSelected.name : $t('pipelines.create-new-type')}}</div>
				<div class="action-close" v-if="pipelineSelected">
					<button class="btn-tbf blue only-icon" @click="editItem(pipelineSelected)" v-if="viewCrud == 'view'">
						<div class="icon"><icon-edit /></div>
					</button>
					<button class="btn-tbf white only-icon" @click="resetToList('item')" v-if="viewCrud == 'edit'">
						<div class="icon"><icon-close /></div>
					</button>
				</div>
			</div>

			<div class="form" v-if="['create', 'edit'].includes(viewCrud)">
                <div class="input-group">
                    <div class="label-header">
                        <label class="label">{{ $t('general.name') }}*</label>
                        <div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        <div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
                    </div>
                    <div class="input-box bg-white" v-bind:class="{has_error: $v.name.$error}">
                        <div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('pipelines.name-ph')" class="input-text no-icon" v-model="name">
                    </div>
                </div>

                <div class="input-group">
                    <div class="label-header">
                        <label class="label">{{$t('pipelines.type')}}</label>
                    </div>

                    <div class="input-box bg-white">
                        <div class="icon-left"><icon-box-archive /></div>
                        <div class="icon-right" v-if="selectedType != ''" @click.stop="selectedType = ''"><icon-close class="icon-clear" /></div>
                        <multiselect 
                            v-model="selectedType"
                            v-bind:class="{populate: selectedType != ''}"
                            :options="optionsTypes"
                            :allow-empty="true"
                            :show-labels="false"
                            track-by="value" 
                            label="name"
                        >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('pipelines.choose-type') }}
                                </span>
                            </template>
                        </multiselect>
                    </div>
                </div>

				<div class="input-group">
                    <div class="label-header">
                        <label class="label">{{ $t('pipelines.stages') }}</label>
                    </div>

					<div class="stages">
						<draggable v-model="stages" handle=".handle" ghost-class="ghost">
							<div class="group-stage" v-for="(stage, index) in stages">
								<span class="handle">&#8597;</span>
								<div class="input-box flex bg-white">
									<!-- <label class="checkbox-tbf type-radio">
										<input type="radio" :value="index" v-model="is_done_stage">
										<span class="checkmark"></span>
									</label> -->

									<input type="text" :placeholder="$t('pipelines.name-ph')" class="input-text no-extra-pad" v-model="stage.name">
									<div class="form__input">
										<v-swatches
											v-model="stage.color_code"
											popover-x="left"
											swatches="text-advanced"
										></v-swatches>
									</div>
								</div>

								<div class="delete-stage" @click="storeForDelete(index, stage)">
									<icon-trash />
								</div>
							</div>
						</draggable>

						<div class="group-stage">
							<div class="input-box flex bg-white">
								<!-- <label class="checkbox-tbf type-radio">
									<input type="radio" value="1" v-model="is_done_stage">
									<span class="checkmark"></span>
								</label> -->

								<input type="text" :placeholder="$t('pipelines.name-ph')" class="input-text no-extra-pad" v-model="stage_name">
								<div class="color-select">
									<div class="diez-character">#</div>
									<input type="text" class="input-text no-extra-pad" v-model="color_code" maxlength="6" :placeholder="$t('departments.choose_color_ph')">
									<div class="form__input">
										<v-swatches
											v-model="color"
											popover-x="left"
											swatches="text-advanced"
										></v-swatches>
									</div>
								</div>
							</div>
							<button class="btn-tbf blue add-stage" @click="pushStage">
								<div class="text">Adauga stadiu nou</div>
							</button>
						</div>
					</div>
				</div>

				<div class="input-group" v-if="selectedType">
					<div class="label-header">
                        <label class="label">{{ $t('pipelines.default_pipeline') }}</label>
                    </div>
					<div class="item-switch">
						<div class="name">{{ $t('pipelines.default_pipeline_ex', {type: selectedType ? selectedType.name : '' }) }}</div>
						<div class="action">
							<label class="switch">
								<input type="checkbox" value="1" v-model="default_pipeline">
								<span class="slider round"></span>
							</label>
						</div>
					</div>
				</div>

                <div class="form-submit">
                    <div class="error-msg-settings" v-show="error_message!=''">{{error_message}}</div>
                    <button id="buttonCreate" class="btn-tbf blue" @click="saveAction(viewCrud == 'create' ? 'create' : 'update')" :disabled="loadingAction">
                        <div class="loader-spin" v-if="loadingAction"></div>
                        <div class="text" v-else>{{ viewCrud == 'create' ? $t('pipelines.add_pipeline') : $t('general.update') }}</div>
                    </button>
                </div>
            </div>
			<div class="data-item" v-else>
                <div class="label" v-if="pipelineSelected.name">{{ $t('general.name') }}</div>
                <div class="text" v-if="pipelineSelected.name">{{ pipelineSelected.name }}</div>

                <div class="label" v-if="pipelineSelected.type">{{ $t('pipelines.type') }}</div>
                <div class="text" v-if="pipelineSelected.type">{{ $t(`pipelines.types.${pipelineSelected.type}`) }}</div>
				
				<div class="label">{{ $t('pipelines.default_pipeline') }}</div>
				<div class="text">{{ pipelineSelected.is_default ? $t(`pipelines.is_default_pipeline`) : $t(`pipelines.not_default_pipeline`) }}</div>
				
				<template v-if="stages.length">
					<div class="label" >{{ $t('pipelines.stages') }}</div>
					<div class="stages">
						<div class="stage" v-for="stage in stages">
							<div class="stage-color" :style="`background-color: ${stage.color_code}`"></div>
							{{ stage.name }}
						</div>
					</div>
				</template>
            </div>
		</div>
    </div>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconBoxArchive from '@/components/Icons/BoxArchive'
	import IconPipeline from '@/components/Icons/IconPipeline'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'
	import { required } from 'vuelidate/lib/validators'
	import VSwatches from 'vue-swatches'
	import 'vue-swatches/dist/vue-swatches.css'
	import draggable from 'vuedraggable'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
			IconBoxArchive,
			IconPipeline,
			LoaderSettingsPage,
			VSwatches,
			draggable
		},
		computed: {
			soft_organigram() {
				return this.$store.getters['applications/getApplication']('Organigrama');
			}
		},
		watch: {
			color (newValue, oldValue) {
				this.color_code = newValue.substring(1);
			},
			color_code (newValue, oldValue) {
				this.color = newValue ? `#${newValue}` : ''
			},
			pipelineSelected (newValue) {
				this.stages = [];
				if(newValue.hasOwnProperty('id')) {
					this.getStages();
				}
			}
		},
		data() {
			return {
				viewCrud: 'create',
				name: '',
				optionsTypes: [
                    { name: this.$t('pipelines.types.crm'), value: 'crm' },
                    { name: this.$t('pipelines.types.project'), value: 'project' },
                    { name: this.$t('pipelines.types.task'), value: 'task' }
                ],
			  	selectedType: '',
                error_message: '',
				errorsBe: {},
                pipelines: [],
				pipelineSelected: '',
                loadingAction: false,
				stages: [],
				stage_name: '',
				color_code: '',
				color: '',
				is_done_stage: 0,
				stagesForDelete: [],
				default_pipeline: 0
			}
		},
		async mounted(){
			this.$root.$on('refreshDepartmentsIndex', () => {
				this.resetToList();
				this.getPipelines()
			})
		},
		validations: {
			name: {required}
		},
		methods: {
			saveAction(type){
                this.loadingAction = true;
				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
						name: this.name,
						type: this.selectedType ? this.selectedType.value : '',
						is_default: this.default_pipeline,
						stages: this.stages.map((el, index) => { return {
							id: el.id ? el.id : null,
							order_no: index,
							name: el.name,
							color_code: el.color_code
						}; } )
					}

					if(type == 'create'){
						this.createPipeline(objData)
					}else if(type == 'update'){
						this.updatePipeline(objData)
					}
				} else {
                    this.loadingAction = false;
				}
			},
			createPipeline(objData){
				axios.post('/pipelines/store', objData)
				.then(({data}) => {
                    this.$emit('refresh_list');
					this.resetToList('item');
                    this.$v.$reset();
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish');
					this.errorsBe = error.response.data.errors;
				})
				.finally(() => {
                    this.loadingAction = false;
				})
			},
            populateCrud(pipeline) {
                this.name = pipeline.name;
                this.selectedType = this.optionsTypes.find(el => el.value == pipeline.type);
                this.default_pipeline = pipeline.is_default;
            },
			updatePipeline(objData){
				axios.patch(`/pipelines/${this.pipelineSelected.id}`, objData)
				.then(({data}) => {
                    this.$emit('refresh_list');
					this.resetToList('item');
					this.$v.$reset();
				})
				.catch(error => {
					this.errorsBe = error.response.data.errors;
					if(error.response.status == 500){
						this.error_message = this.$t('error.500');
					}
				})
				.finally(() => {
                    this.loadingAction = false;
				})
			},
			editItem(pipeline){
				this.scrollTop()
				this.viewCrud = 'edit';
				
				this.pipelineSelected = pipeline;

				this.name = pipeline.name;
				this.selectedType = pipeline.type ? this.optionsTypes.find(el => el.value == pipeline.type) : '';
                this.default_pipeline = pipeline.is_default;
			},
			resetToList(from){
				this.viewCrud = 'create'

				if(from == 'item') {
					this.pipelineSelected = '';
				}

				this.name = '';
				this.selectedType = '';
				this.default_pipeline = 0;
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			},
			pushStage(){ 
				this.stages.push({ color_code: this.color, name: this.stage_name })

				// clear inputs new stage
				this.stage_name = '';
				this.color = '';
				this.color_code = '';
			},
			storeForDelete(index, stage) { 
				if(stage.id) { 
					this.stagesForDelete.push(stage.id);
				}

				this.stages.splice(index, 1);
			},
			getStages() {
				axios.get(`/${this.pipelineSelected.id}/stages`)
				.then(({data}) => {
					this.stages = data.data;
				})
			}
		}
	};
</script>